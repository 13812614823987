.App {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-tabs-content-holder {
  padding-left: 150px;
  padding-right: 150px;
}

.domain-tabs .ant-collapse-expand-icon,
.domain-tabs .ant-collapse-header-text span {
  cursor: pointer;
}
.domain-tabs .ant-collapse-header {
  cursor: auto !important;
}
.domain-tabs .ant-tabs-tab-btn {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.domain-tabs .ant-tabs-tab,
.domain-tabs .ant-tabs-nav-add {
  background-color: #efefef !important;
}
.domain-tabs .ant-tabs-tab-active {
  background-color: #fff !important;
}
.page-url-collapse .ant-collapse-content-box {
  padding-left: 40px !important;
}

.heading-collapse .ant-collapse-header {
  padding-left: 0px !important;
}
.sort-select{
  display: flex;
}
.sort-select .ant-select{
  width: 100px;
}
.text-primary {
  color: #1890ff;
}
.cursor-pointer {
  cursor: pointer;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.gutter-row span{
  word-wrap: break-word;
}
.compare-btn{
  display: flex;
    justify-content: end;
    margin-right: 10px;
}
.compare-container{
  justify-content: center;
  
}
.compare-container .ant-card{
  box-shadow: 1px -1px rgba(245, 245, 245, 0.6);
}
.mr-1{
  margin-right:0.5rem
}